import './buttonstyle.css';

const Button = ({text, onClick, classes}) => {
    return (
        <>
            <button className={`button + ${classes}`} onClick={onClick} type='button'>{text}</button>
        </>
    )
}

export default Button;