import axios from 'axios';
import allProductsData from '../Data/data-config.json';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const handleSortBy = (e, categoryProductsData, setCategoryProductsData, originalProductsData) => {
    const sortValue = e.target.value;
    if(sortValue === "lowPrice") {
        const sortedList = [...categoryProductsData].sort((a, b) => a.price - b.price);
        setCategoryProductsData(sortedList);
    }
    else if(sortValue === "highPrice") {
        const sortedList = [...categoryProductsData].sort((a, b) => b.price - a.price);
        setCategoryProductsData(sortedList);
    }
    else {
        setCategoryProductsData(originalProductsData);
    }
}

export const handleNaracajMalo = () => {
    window.location.href = 'https://www.moi-namirnici.mk/';
}

export const handleAddNaracajGolemo = async (e, quantity, product, cartItems, setCartItems, setError) => {
    e.preventDefault();
    const quantityValue = parseInt(quantity.current.value);
    if(isNaN(quantityValue)) {
        setError("Внесете точни вредности!");
        return null;
    }
    const productData = allProductsData.find((item) => item.title === product.current.value);
    try {
        const existingItem = cartItems.find(item => item.title === productData.title);
        if (existingItem !== undefined) {
            const updateCart = cartItems.map((item) => {
                if (item.title === existingItem.title) {
                    return {
                        ...item,
                        quantityValue: item.quantityValue + quantityValue
                    };
                }
                return item;
            });

            setCartItems(updateCart);
            localStorage.setItem("naracajGolemoProdukti", JSON.stringify(updateCart));
        } else {
            const updateCart = [...cartItems, { ...productData, quantityValue }];
            setCartItems(updateCart);
            localStorage.setItem("naracajGolemoProdukti", JSON.stringify(updateCart));
        }
    } catch (error) {
        console.log(error);
    }
    setError(``);
}


export const deleteProductFromCart = async (product, cartItems, setCartItems) => {
    try {
        const updateCart = await cartItems.filter((item) => item.title !== product.title);
        setCartItems(updateCart);
    } catch (error) {
        console.log(error);
    }
}


export const handleShowNumberProducts = (showRef, setShowNumberProducts, originalProductsData, setCategoryProductsData) => {
    setShowNumberProducts(showRef.current.value);
    let newList = [];
    for(let i = 0; i<showRef.current.value; i++) {
       if(originalProductsData[i]) {
            newList.push(originalProductsData[i]);
       }
    }
    setCategoryProductsData(newList);
}

export const handlePagination = (page, showRef, originalProductsData) => {
    let newList = [];
    for(let i = ((page-1) * showRef.current.value); i<(page * showRef.current.value); i++) {
        if(originalProductsData[i]) {
             newList.push(originalProductsData[i]);
        }
    }
    return newList;
}


export const handleSendEmailToOwner = async (name, surname, email, phone, address, company, danocenBroj, setError, setCartItems) => {
    try {

        if(name.trim() === '' || surname.trim() === '' || phone.trim() === '' || address.trim() === '' || company.trim() === '' || danocenBroj.trim() === '') {
            setError("Ве молиме потполнете ги сите полиња");
            return null;
        }
        setError('');
        const productsData = JSON.parse(localStorage.getItem("naracajGolemoProdukti"));
        const contentHTML = `
        <h1 style="text-align:center">Извршена е нова нарачка!</h1>
        <table style="width: 100%; border-collapse: collapse;">
            <thead>
            <tr style="background: #253d4e; color: #fff; text-align:center">
                <th style="padding: 15px;">Име</th>
                <th style="padding: 15px;">Презиме</th>
                <th style="padding: 15px;">Е-Маил адреса</th>
                <th style="padding: 15px;">Телефонски број</th>
                <th style="padding: 15px;">Адреса</th>
                <th style="padding: 15px;">Име на компанија</th>
                <th style="padding: 15px;">Даночен број</th>
            </tr>
            </thead>
            <tbody>
            <tr style="text-align:center">
                <td style="padding: 15px;">${name}</td>
                <td style="padding: 15px;">${surname}</td>
                <td style="padding: 15px;">${email}</td>
                <td style="padding: 15px;">${phone}</td>
                <td style="padding: 15px;">${address}</td>
                <td style="padding: 15px;">${company}</td>
                <td style="padding: 15px;">${danocenBroj}</td>
            </tr>
            </tbody>
        </table>
        <h3 style="text-align:center">Листа на нарачани продукти</h3>`;

        const productsTableHTML = `
            <table style="width: 100%; border-collapse: collapse;">
                <thead>
                    <tr style="background: #253d4e; color: #fff">
                        <th style="padding: 15px;">Име на продукт</th>
                        <th style="padding: 15px;">Категорија на продукт</th>
                        <th style="padding: 15px;">Количина</th>
                    </tr>`;

        const generateTable = productsData.map((item) => {
            return `<tr style="text-align:center">
                        <td style="padding: 15px;">${item.title}</td>
                        <td style="padding: 15px;">${item.category}</td>
                        <td style="padding: 15px;">${item.quantityValue}</td>
                    </tr>`})

        const resultText = generateTable.join(' ');
        const finalText = contentHTML + (productsTableHTML + resultText + `</table>`);
        emailjs.init('Gyt6eaNlMywdvwKnX');
          emailjs.send('service_a82im3n', 'template_5113xbs',{
              html_content: finalText,
              html: true,
            })
            .then(() => {
                localStorage.setItem("naracajGolemoProdukti", JSON.stringify([]));
                setCartItems([]);
                successToast();
            })
            .catch((error) => {
              console.error('Email could not be sent:', error);
              errorToast();
            });
    } catch (error) {
        console.log(error);
    }
}


export const handleSendContactEmail = async (person, email, phone, title, text, setError, setSuccessState) => {
    try {
        if(person.trim() === '' || email.trim() === '' || phone.trim() === '' || text.trim() === '' || title.trim() === '') {
            setError("Ве молиме потполнете ги сите полиња");
            return null;
        }
        const contentHTML = `
        <h1 style="text-align:center">Информации</h1>
        <table style="width: 100%; border-collapse: collapse;">
            <thead>
            <tr style="background: #253d4e; color: #fff; text-align:center">
                <th style="padding: 15px;">Име и презиме</th>
                <th style="padding: 15px;">Е-Маил адреса</th>
                <th style="padding: 15px;">Телефонски број</th>
            </tr>
            
            </thead>
            <tbody>
            <tr style="text-align:center">
                <td style="padding: 15px;">${person}</td>
                <td style="padding: 15px;">${email}</td>
                <td style="padding: 15px;">${phone}</td>
            </tr>
            <tr style="background: #253d4e; color: #fff; text-align:center">
                <th style="padding: 15px;">Наслов на пораката</th>
                <th style="padding: 15px;">Порака</th>
                <th></th>
            </tr>
            <tr style="text-align:center">
                <td style="padding: 15px;">${title}</td>
                <td style="padding: 15px;" rowspan="2">${text}</td>
            </tr>
            </tbody>
        </table>`;

        emailjs.init('Gyt6eaNlMywdvwKnX');
        emailjs.send('service_a82im3n', 'template_1yjjrl8',{
            html_content: contentHTML,
            html: true,
          })
          .then((response) => {
            console.log('Email sent:', response);
            successToast();
            setSuccessState(true);
          })
          .catch((error) => {
            console.error('Email could not be sent:', error);
            errorToast();
          });
        
    } catch (error) {
        console.log(error);
    }
}


export const successToast = () => {
    toast.success('Успешно испратено!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
        });
}

export const errorToast = () => {
    toast.error('Грешка во испраќањето!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
        });
}