import logo from '../../Assets/Images/logo.jpg';
import Navigation from '../Navigation/Navigation';
import './headerstyle.css';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <>
          <header>
            <div className="container pt-4 pb-4">
                <div className="row align-items-center">
                    <div className="col-md-2">
                        <Link to="/"><img src={logo} alt="" className="logo-img"/></Link>
                    </div>
                    <div className="col-md-10">
                        <Navigation/>
                    </div>
                </div>
            </div>
          </header>
        </>
    )
}

export default Header;