import './footerstyle.css';
import logo from '../../Assets/Images/logo.jpg';
import { CiLocationOn } from 'react-icons/ci';
import { BsTelephone } from 'react-icons/bs';
import { PiTelegramLogoLight } from 'react-icons/pi';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { BiLogoFacebook, BiLogoInstagram } from 'react-icons/bi';

const Footer = () => {
    return (
        <>
            <footer className="pt-4">
                <div className="container pt-4 pb-4 footer-container">
                    <div className="row">
                        <div className="col-md-4">
                            <Link to="/"><img src={logo} alt="" className="logo-img"/></Link>
                            <ul className="pt-4">
                                <li className="mb-2 text-white"><CiLocationOn className='footer-icon'/><span className="fw-bold mx-2">Адреса :</span>Скопје, Македонија</li>
                                <li className="mb-2 text-white"><BsTelephone className='footer-icon'/><span className="fw-bold mx-2">Телефонски број :</span> (+389) - 75 555 555</li>
                                <li className="mb-2 text-white"><PiTelegramLogoLight className='footer-icon'/><span className="fw-bold mx-2">Емаил :</span>sanja@rikrom.com.mk</li>
                                <li className='text-white'><AiOutlineClockCircle className='footer-icon'/><span className="fw-bold mx-2">Работни саати :</span>10:00 - 18:00 Пон - Пет</li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <h4 className="footer-title">Компанија</h4>
                            <ul>
                                <li className='mb-2'><Link to="/" className='text-white'>Дома</Link></li>
                                <li className='mb-2'><Link to="/about" className='text-white'>За нас</Link></li>
                                <li className='mb-2'><Link to="/contact" className='text-white'>Контакт</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <h4 className="footer-title">Производи</h4>
                            <ul>
                                <li className='mb-2'><Link to='/category/pekarstvo' className='text-white'>Пекарство</Link></li>
                                <li className='mb-2'><Link to='/category/slatkarstvo' className='text-white'>Слаткарство</Link></li>
                                {/* <li className='mb-2'><Link to='/category/brasna'>Брашна</Link></li> */}
                                <li><Link to='/category/recepti' className='text-white'>Рецепти</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container pt-4 pb-4">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <p className='text-white'>@ 2023, <Link to='https://cloudytechnologies.mk/'><span className='primary-color fw-bold'>Cloudytechnologies</span></Link></p>
                            <p className='text-white'>сите права се задржани</p>
                        </div>
                        <div className="col-md-4 text-center footer-item-none">
                            <p className='primary-color fw-bold navigation-number'>+389 2 2520414</p>
                        </div>
                        <div className="col-md-4 d-flex footer-item-none align-items-center">
                            <p className='text-white'>Заследете не</p>
                            <ul className="d-flex mx-3">
                                <li className='footer-social-icon-wrapper'><BiLogoFacebook className='footer-social-icon'/></li>
                                <li className='footer-social-icon-wrapper'><BiLogoInstagram className='footer-social-icon'/></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;