import Header from "../../Components/Header/Header";
import './productpagestyle.css';
import Button from "../../Components/Button/Button";
import Footer from "../../Components/Footer/Footer";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import data from '../../Utils/Data/data-config.json';
import { handleNaracajMalo } from "../../Utils/App Functions/app-functions";

const ProductPage = () => {

    const navigate = useNavigate();
    const { title } = useParams();
    const [productData, setProductData] = useState([]);

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    useEffect(() => {
        window.scrollTo(0,0);
        const matchProduct = data.find((item) => item.titleEn === title);
        setProductData(matchProduct);
    }, [title]);

    const handleNaracajGolemo = () => {
        navigate('/naracaj-golemo');
    }

    return (
        <>
            <Header/>

            <section className="pt-5 pb-5">
                <div className="container">
                    <div className="row pt-4">
                        <div className="col-md-6 d-flex product-page-img-container">
                            <div className="product-page-img-wrapper mx-auto">
                               {
                                productData.images&& <img src={productData.images[0].img} alt=""  className="w-100 h-100"/>
                               }
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="product-page-info-wrapper px-4">
                                <h1 className="product-title">{productData.title}</h1>
                                {/* <p className="product-price primary-color mt-4">{productData.price}<span className="product-price-inner">денари</span></p> */}
                                {/* <p className="secondary-color fw-bold mt-4">пакување</p> */}
                                <p className="grey-color mt-4">{productData.desc}</p>
                                <Button text="Нарачај мало" classes="mt-3" onClick={handleNaracajMalo}/>
                                <Button text="Нарачај големо" classes="mx-3" onClick={handleNaracajGolemo}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default ProductPage;