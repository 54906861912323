import { Link } from 'react-router-dom';
import './productcardstyle.css';

const ProductCard = ({props}) => {

    return (
        <>
            <div className='col-md-3 mb-4'>
               <Link to={`/product/${props.titleEn}`}>
               <div className="product-card-container d-inline-flex flex-column">
                        <div className="product-card-img-wrapper">
                            <img src={props.images[0].img} alt='' className='w-100'/>
                        </div>
                        <div className="product-card-info-wrapper">
                            <span className='product-card-brand mt-3 mb-2 d-inline-block'>{props.brand}</span>
                            <h2 className='product-card-title'>{props.title}</h2>
                            {/* <span className='product-card-price d-block text-end'>{props.price} ден</span> */}
                        </div>
                </div>
               </Link>
            </div>
        </>
    )
}

export default ProductCard;