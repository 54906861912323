import './Assets/Styles/App.css';
import HomePage from "./Pages/Home Page/HomePage";

function App() {
  return (
    <>
    <HomePage/>
    </>
  );
}

export default App;
