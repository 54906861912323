export const dataCompanies = [
    {
        id: 2,
        title: `Dimas`,
        image: `/Images/Products Images/dimas.webp`,
        url: `https://dimas.mk`,
        category: `pekarstvo`
    },
    {
        id: 3,
        title: `Dubor`,
        image: `/Images/Products Images/dubor_img.webp`,
        url: `https://www.dubor.de`,
        category: `pekarstvo`
    },
    {
        id: 4,
        title: `Edme`,
        image: `/Images/Products Images/edme_img.webp`,
        url: `https://www.edme.com`,
        category: `pekarstvo`
    },
    {
        id: 5,
        title: `Glazir`,
        image: `/Images/Products Images/glazir_img.webp`,
        url: `https://www.glazir.hr`,
        category: `slatkarstvo`
    },
    {
        id: 6,
        title: `Irca`,
        image: `/Images/Products Images/irca_logo.webp`,
        url: `https://www.ircagroup.com`,
        category: `slatkarstvo`
    },
    {
        id: 7,
        title: `Nase Klasje`,
        image: `/Images/Products Images/nase_klasije_logo.webp`,
        url: `https://www.naseklasje.hr`,
        category: `pekarstvo`
    },
    {
        id: 8,
        title: `Zeelandia`,
        image: `/Images/Products Images/zeelandia_logo.webp`,
        url: `https://www.zeelandia.com`,
        category: `pekarstvo`
    },
    {
        id: 9,
        title: `Isa Italy`,
        image: `/Images/Products Images/isa_italy.webp`,
        url: `https://www.isaitaly.com`,
        category: `pekarstvo`
    },
    {
        id: 10,
        title: `Mulino Caputo`,
        image: `/Images/Products Images/caputo_logo.webp`,
        url: `https://www.mulinocaputo.it`,
        category: `pekarstvo`
    },
    {
        id: 11,
        title: `Mec3`,
        image: `/Images/Products Images/mec_3_logo.webp`,
        url: `https://www.mec3.com`,
        category: `slatkarstvo`
    },
    {
        id: 12,
        title: `Remia`,
        image: `/Images/Products Images/remia_logo.webp`,
        url: `https://www.remia.com`,
        category: `pekarstvo`
    },
    {
        id: 13,
        title: `SPM`,
        image: `/Images/Products Images/spm_logo.webp`,
        url: `https://www.spm.drink-systems.com`,
        category: `pekarstvo`
    },
]