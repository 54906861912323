import { useParams } from "react-router";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import PageHeader from "../../Components/Page Header/PageHeader";
import { useEffect, useRef, useState } from "react";
import productsData from '../../Utils/Data/data-config.json';
import ProductCard from "../../Components/Product Card/ProductCard";
import { handlePagination, handleShowNumberProducts } from "../../Utils/App Functions/app-functions";
import { PaginationControl } from "react-bootstrap-pagination-control";


const CompanyProductsPage = () => {
    const showRef = useRef();
    const { company } = useParams();
    const [originalProductsData, setOriginalProductsData] = useState([]);
    const [categoryProductsData, setCategoryProductsData] = useState([]);
    const [productsNumber, setProductsNumber] = useState();
    const [page, setPage] = useState(1);
    const [showNumberProducts, setShowNumberProducts] = useState();

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    useEffect(() => {
        const filterProducts =  productsData.filter((item) => item.brand === company);
        setOriginalProductsData(filterProducts);
        setCategoryProductsData(filterProducts);
        setProductsNumber(filterProducts.length);
        setShowNumberProducts(showRef.current.value);
    }, [company])

    useEffect(() => {
        setCategoryProductsData(handlePagination(page, showRef, originalProductsData))
    }, [originalProductsData, page])

    useEffect(() => {
        setCategoryProductsData(handlePagination(page, showRef, originalProductsData))
    }, [page])
    
    return (
        <>
            <Header/>
            <PageHeader title={company}/>
            <section className="products-page pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 products-container">
                            <div className="products-filter-container mb-4 d-flex justify-content-between align-items-center">
                                <p className="grey-color">Вкупно <span className="primary-color fw-bold">{originalProductsData.length}</span> производи</p>
                                <div className="products-filter-wrapper d-flex gap-4">
                                    <div className="products-filter-show d-flex align-items-center">
                                        <p>Прикажи: </p>
                                        <select ref={showRef} onChange={() => handleShowNumberProducts(showRef, setShowNumberProducts, originalProductsData, setCategoryProductsData)}>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="12">12</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                           <div className="row">
                            {
                                    categoryProductsData&&categoryProductsData.map((item, index) => (
                                        <ProductCard props={item} key={index}/>
                                    ))
                                }
                                 <PaginationControl
                                    page={page}
                                    between={4}
                                    total={productsNumber}
                                    limit={showNumberProducts}
                                    changePage={(page) => {
                                    setPage(page)
                                    }}
                                    ellipsis={1}
                                />
                           </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default CompanyProductsPage;