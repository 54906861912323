import { useEffect, useRef, useState } from "react";
import Button from "../../Components/Button/Button";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { handleSendContactEmail } from "../../Utils/App Functions/app-functions";
import './contactpagestyle.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactPage = () => {


    const [successState, setSuccessState] = useState(false);
    const [error, setError] = useState()
    const personRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const textRef = useRef();
    const titleRef = useRef();

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    useEffect(() => {
        personRef.current.value = ``;
        emailRef.current.value = ``;
        phoneRef.current.value = ``;
        titleRef.current.value = ``;
        textRef.current.value = ``;
        setSuccessState(false);
    }, [successState])

    return (
        <>
            <Header/>
            <section className="pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="contact-map-wrapper">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2965.15074989786!2d21.481098576600242!3d41.99704015795803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13543f2298faed4d%3A0xdec8859c5da27f2c!2sRikrom%20DOO%20%26%20Rikapri%20d.o.o.e.l!5e0!3m2!1sen!2smk!4v1698087476291!5m2!1sen!2smk" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-5">
                        <div className="col-md-6">
                            <div className="contact-form-wrapper">
                                <p className="primary-color fw-bold mb-3">Контакт форма</p>
                                <h3 className="secondary-color fw-bold">Испратете ни порака</h3>
                                <form className="mt-4">
                                    <div className="d-flex gap-3">
                                        <input ref={personRef} type="text" placeholder="Вашето име и презиме" className="form-control pt-3 pb-3 px-4"/>
                                        <input ref={emailRef} type="email" placeholder="Вашиот е-маил" className="form-control pt-3 pb-3 px-4"/>
                                    </div>
                                    <div className="d-flex gap-3 mt-4">
                                        <input ref={phoneRef} type="text" placeholder="Вашиот телефонски број" className="form-control pt-3 pb-3 px-4"/>
                                        <input ref={titleRef} type="text" placeholder="Наслов на пораката" className="form-control pt-3 pb-3 px-4"/>
                                    </div>
                                    <textarea ref={textRef} className="form-control mt-4 pt-3 pb-3 px-4" placeholder="Внесете порака"/>
                                    {error ? <p className='text-danger mt-3'>{error}</p> : ``}
                                    <Button text="Испратете" classes="mt-4 pt-3 pb-3 px-5" 
                                    onClick={() => handleSendContactEmail(
                                        personRef.current.value,
                                        emailRef.current.value,
                                        phoneRef.current.value,
                                        titleRef.current.value,
                                        textRef.current.value,
                                        setError,
                                        setSuccessState
                                    )}/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
        <ToastContainer />
      </div>

            <Footer/>
        </>
    )
}

export default ContactPage;