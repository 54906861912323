import { useContext, useEffect } from "react";
import Header from "../../Components/Header/Header";
import NaracajGolemoForm from "../../Components/Naracaj Golemo Form/NaracajGolemoForm";
import NaracajGolemoSelect from "../../Components/Naracaj Golemo Select/NaracajGolemoSelect";
import NaracajGolemoTable from "../../Components/Naracaj Golemo Table/NaracajGolemoTable";
import Navigation from "../../Components/Navigation/Navigation";
import { CartContext } from "../../Utils/Hooks/CartContextComponent";
import Footer from "../../Components/Footer/Footer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const NaracajGolemoPage = () => {

    const { cartItems, setCartItems } = useContext(CartContext);

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <>
        <Header/>
        <NaracajGolemoSelect/>
        {
            cartItems.length === 0 ? `` :
            <>
            <section className="container pt-5 pb-5">
            <h5 className="mb-3 secondary-color fw-bold">Вашите внесени продукти</h5>
            <NaracajGolemoTable/>
        </section>
        <section className="container pt-5 pb-5">
            <h5 className="mb-3 secondary-color fw-bold">Внесете ги вашите информации</h5>
            <NaracajGolemoForm/>
        </section>
            </>
        }
        <Footer/>
        <ToastContainer />
        </>
    )
}

export default NaracajGolemoPage;