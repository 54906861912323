import { createContext, useEffect, useState } from "react";

export const CartContext = createContext();

const CartContextComponent = ({children}) => {

    const fetchItems = JSON.parse(localStorage.getItem("naracajGolemoProdukti"));
    const [cartItems, setCartItems] = useState(fetchItems === null ? [] : fetchItems);

    useEffect(() => {
        localStorage.setItem('naracajGolemoProdukti', JSON.stringify(cartItems));
    }, [cartItems]);

    return  <CartContext.Provider value={{ cartItems, setCartItems }}>
    {children}
    </CartContext.Provider>
}

export default CartContextComponent;