import { useContext } from "react";
import { CartContext } from "../../Utils/Hooks/CartContextComponent";
import './naracajgolemotablestyle.css';
import { AiOutlineDelete } from 'react-icons/ai';
import { deleteProductFromCart } from "../../Utils/App Functions/app-functions";

const NaracajGolemoTable = () => {

    const { cartItems, setCartItems } = useContext(CartContext);

    return (
        <>
         <div className="table-responsive naracaj-golemo-table">
        <table className="table align-baseline table-sm text-center">
          <thead>
            <tr>
              <th scope="col" className="size-19">
                #
              </th>
              <th scope="col"></th>
              <th scope="col" className="size-19">
                Име на продукт
              </th>
              <th scope="col" className="size-19">
                Количина
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {cartItems &&
              cartItems.map((item, index) => (
                <tr key={item.title}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    <img
                      src={item.images[0].img}
                      alt=""
                      style={{ width: "100px" }}
                    />
                  </td>
                  <td className="size-20">{item.title}</td>
                  <td className="size-20">{item.quantityValue}</td>
                  <td>
                    <AiOutlineDelete
                      onClick={() =>
                        deleteProductFromCart(
                          item,
                          cartItems,
                          setCartItems
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
        </>
    )
}

export default NaracajGolemoTable;