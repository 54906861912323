import './navigationstyle.css';
import { RxDashboard } from "react-icons/rx";
import { BsHeadphones } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineShoppingCart } from 'react-icons/ai';

const Navigation = () => {

    const navigate = useNavigate();

    const handleCartRedirection = () => {
        navigate('/naracaj-golemo')
    }

    return (
        <>
            {/* <nav className="navigation-section pb-4 pt-4 navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                            <ul className="d-flex flex-fill justify-content-around fw-bold align-items-center">
                                <li>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle navigation-dropdown-button" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <RxDashboard/> Сите категории
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li className="dropdown-item mb-2"><Link to="/category/pekarstvo" className='secondary-color fw-bold'>Пекарство</Link></li>
                                            <li className="dropdown-item"><Link to="/category/slatkarstvo" className='secondary-color fw-bold'>Слаткарство</Link></li>
                                            <li className="dropdown-item"><Link to="/category/brasna" className='secondary-color fw-bold'>Брашна</Link></li>
                                            <li className="dropdown-item"><Link to="/category/recepti" className='secondary-color fw-bold'>Рецепти</Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li><Link to="/" className='secondary-color'>Дома</Link></li>
                                <li><Link to="/contact" className='secondary-color'>Контакт</Link></li>
                                <li><Link to="/about" className='secondary-color'>За нас</Link></li>
                            </ul>
                            <ul className="flex-fill text-end d-flex justify-content-end align-items-center">
                                <li><BsHeadphones className='navigation-headphones'/> <p className='d-inline-block navigation-number'>+389 2 2520414</p></li>
                                <li className='mx-4'><AiOutlineShoppingCart className='nav-cart-icon' onClick={handleCartRedirection}/></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav> */}

<nav className="navbar navbar-expand-lg navigation-section">
  <div className="container-fluid">
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="d-flex flex-fill justify-content-around fw-bold align-items-center navbar-nav">
                                <li><Link to="/" className='text-white'>Дома</Link></li>
                                <li><Link to="/contact" className='text-white'>Контакт</Link></li>
                                <li><Link to="/about" className='text-white'>За нас</Link></li>
                                <li className='nav-item'>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle navigation-dropdown-button" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <RxDashboard/> Сите категории
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li className="dropdown-item mb-2"><Link to="/category/pekarstvo" className='secondary-color fw-bold'>Пекарство</Link></li>
                                            <li className="dropdown-item"><Link to="/category/slatkarstvo" className='secondary-color fw-bold'>Слаткарство</Link></li>
                                            {/* <li className="dropdown-item"><Link to="/category/brasna" className='secondary-color fw-bold'>Брашна</Link></li> */}
                                            {/* <li className="dropdown-item"><Link to="/category/recepti" className='secondary-color fw-bold'>Рецепти</Link></li> */}
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <ul className="flex-fill text-end d-flex justify-content-end align-items-center navbar-nav">
                                <li className='nav-item-none'><BsHeadphones className='navigation-headphones'/> <p className='d-inline-block navigation-number'>+389 2 2520414</p></li>
                                <li className='mx-4 nav-item-cart'><AiOutlineShoppingCart className='nav-cart-icon' onClick={handleCartRedirection}/></li>
                            </ul>
    </div>
  </div>
</nav>
        </>
    )
}




export default Navigation;