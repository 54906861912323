import Header from "../../Components/Header/Header";
import image1 from '../../Assets/Images/about-1.png';
import './aboutpagestyle.css';
import Footer from "../../Components/Footer/Footer";
import { useEffect } from "react";

const AboutPage = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])


    return (
        <>
            <Header/>
            <section className="about-page pt-5 pb-5">
                <div className="container">
                    <div className="row align-items-center pb-5">
                        <div className="col-md-6">
                            <div className="about-img-wrapper d-flex">
                                <img src={image1} alt="" className="w-50 mx-auto"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-info px-5">
                                <h1 className="title-text mb-4">Добредојдовте</h1>
                                <p className="grey-color">Пред повеќе од три децении на македонскиот пазар се отвора семејната компанија "Рикром" која се бави се до денес со трговија на суровини и опрема за пекарство, слаткарство и сладоледи. Во 2003та година како гранка ја развива и сестринската компанија "Рикапри" која започнува да работи и да се фокусира пред се на слаткарскиот програм и сладоледи, за подоцна да се развие во трговија со широк спектар на прехрамбени продукти. И двете компании работат исклучиво големопродажба со големи светски брендови како Barry Callebout, Caputo, Remia, Joygelato, Irca, Glazir, Mantler, Nashe Klasje, Ruitenberg, Dubor, Mek3, Moretti Forni, Longoni, Isa и многу други, снабдувајќи го целосно угостителскиот сектор како во земјата така и во соседните држави.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-5">
                            <div className="col-md-4">
                                <div className="about-info px-5">
                                    <h3 className="title-text mb-4">Кој сме ние</h3>
                                    <p className="grey-color">Во 2020та годинa, во терк со иновативната технолигија и новите побарувања на пазарот се основа онлајн малопродажба под брендот "Мои Намирници" со директна бесплатна достава до секое домаќинство.</p>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="about-info px-5">
                                    <h3 className="title-text mb-4">Наша мисија</h3>
                                    <p className="grey-color">Овој продажен канал го изработивме со многу љубов и ентизијазам кон готвењето и консумирањето квалитетни и здрави намирници. Со светлосна брзина се разви нашата страница на социјалните медиуми, со стотици нарачки и илјадници задоволни клиенти кои денес ни се веќе пред се наши пријатели!</p>
                                </div>
                            </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default AboutPage;