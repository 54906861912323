import { useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import HomeSlider from "../../Components/Home Slider/HomeSlider";
import SwiperComponent from "../../Components/Swiper/SwiperComponent";
import { dataCompanies } from "../../Utils/Data/data-companies";
import Button from "../../Components/Button/Button";
import { useNavigate } from "react-router";

const HomePage = () => {

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0,0);
}, [])

const handleNaracajGolemo = () => {
  navigate('/naracaj-golemo')
}

  return (
    <>
      <Header />
      <main>
      <section className="container pt-5 pb-5">
        <div className="row text-start align-items-center">
            <div className="col-md-6">
                <h1 className="title-text size-32">Рикром ДОО Скопје</h1>
                <p className="grey-color mb-4 mt-4">Рикром Доо Скопје е повеќе од обичен добавувач - ние сме ваш креативен и веродостоен партнер.</p>
                <Button text="Нарачај големо" onClick={handleNaracajGolemo}/>
            </div>
            <div className="col-md-6">
              <HomeSlider />
            </div>
        </div>
      </section>
      <section className="pt-5 pb-5">
        <div className="container">
          <div className="row">
            <h5 className="mb-4 fw-bold">Нашите партнери</h5>
            <SwiperComponent list={dataCompanies}/>
          </div>
        </div>
      </section>
      </main>
      <Footer />
    </>
  );
};

export default HomePage;
