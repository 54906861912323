import { Link } from 'react-router-dom';
import image1 from '../../Assets/Images/home-slider-1.jpg';
import image2 from '../../Assets/Images/home-slider-2.jpg';
import image3 from '../../Assets/Images/home-slider-3.jpg';
import image4 from '../../Assets/Images/home-slider-4.jpg';
import './homesliderstyle.css';
const HomeSlider = () => {
    return (
        <>
            <section className="pt-4 pb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active" data-bs-interval="4000">
                                        <Link to="/company/Mulino%20Caputo"><img src={image1} class="d-block homeslider-image" alt="..."/></Link>
                                    </div>
                                    <div class="carousel-item" data-bs-interval="4000">
                                       <Link to="/company/Barry"><img src={image2} class="d-block homeslider-image" alt="..." /></Link>
                                    </div>
                                    <div class="carousel-item" data-bs-interval="4000">
                                       <Link to="/company/Remia"><img src={image3} class="d-block homeslider-image" alt="..." /></Link>
                                    </div>
                                    <div class="carousel-item" data-bs-interval="4000">
                                       <Link to="/company/Irca"><img src={image4} class="d-block homeslider-image" alt="..." /></Link>
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeSlider;