import { useContext, useRef, useState } from 'react';
import allProductsData from '../../Utils/Data/data-config.json';
import { handleAddNaracajGolemo } from '../../Utils/App Functions/app-functions';
import Button from '../Button/Button';
import { CartContext } from '../../Utils/Hooks/CartContextComponent';

const NaracajGolemoSelect = () => {

    const { cartItems, setCartItems} = useContext(CartContext);
    const quantityRef = useRef();
    const productRef = useRef();
    const [productsData, setProductData] = useState(allProductsData);
    const [error, setError] = useState('');

    return (
        <>
        <section className="pt-5 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2 className='secondary-color fw-bold'>Нарачај Големо</h2>
                    </div>

                    <div className="col-md-12 pt-4">
                        <form>
                            <div className='mb-3'>
                                <label className="form-label secondary-color fw-bold">Изберете продукт</label>
                                <select class="form-select" ref={productRef}>
                                    {
                                        productsData&&productsData.map((item) => (
                                            <option value={item.title}>{item.title}</option>
                                        ))
                                    }
                                </select>
                            </div>

                           <div className='mb-3'>
                                <label className="form-label secondary-color fw-bold">Внесете количина</label>
                                <input type="number" className="form-control" ref={quantityRef}></input>
                                {error ? <p className='text-danger mt-3'>{error}</p> : ``}
                           </div>

                           <div className='mb-3'>
                                <Button text="Внесете го продуктот" onClick={(e) => handleAddNaracajGolemo(e, quantityRef, productRef, cartItems, setCartItems, setError)}/>
                           </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default NaracajGolemoSelect;