import { useParams } from "react-router";
import Header from "../../Components/Header/Header";
import Navigation from "../../Components/Navigation/Navigation";
import PageHeader from "../../Components/Page Header/PageHeader";
import productsData from "../../Utils/Data/data-config.json";
import { useContext, useEffect, useRef, useState } from "react";
import CategoryList from "../../Components/Category List/CategoryList";
import ProductCard from "../../Components/Product Card/ProductCard";
import Footer from "../../Components/Footer/Footer";
import { handlePagination, handleShowNumberProducts, handleSortBy } from "../../Utils/App Functions/app-functions";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import './productspagestyle.css';
import { CategoriesContext } from "../../Utils/Hooks/CategoriesContextComponent";
import { dataCompanies } from "../../Utils/Data/data-companies";
import SwiperComponent from "../../Components/Swiper/SwiperComponent";
const ProductsPage = () => {

    const showRef = useRef();
    const { category } = useParams();
    const { selectedCategories, setSelectedCategories } = useContext(CategoriesContext);
    const [categoryProductsData, setCategoryProductsData] = useState([]);
    const [originalProductsData, setOriginalProductsData] = useState([]);
    const [productsNumber, setProductsNumber] = useState();
    const [page, setPage] = useState(1);
    const [showNumberProducts, setShowNumberProducts] = useState();

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    useEffect(() => {
        const filterProducts =  dataCompanies.filter((item) => item.category === category);        
        setCategoryProductsData(filterProducts);
        setOriginalProductsData(filterProducts);
        setProductsNumber(filterProducts.length);
        setShowNumberProducts(showRef.current.value);
    }, [category])

    useEffect(() => {
        setCategoryProductsData(handlePagination(page, showRef, originalProductsData))
    }, [originalProductsData, page])

    useEffect(() => {
        setCategoryProductsData(handlePagination(page, showRef, originalProductsData))
    }, [page])

    useEffect(() => {
        if(selectedCategories.length === 0) {
            const filterProducts =  productsData.filter((item) => item.category === category);
            setOriginalProductsData(filterProducts);
            setProductsNumber(filterProducts.length);
            return;
        }
        const filteredProducts = productsData.filter(product => {
            return selectedCategories.includes(product.vendor);
          });
        setOriginalProductsData(filteredProducts);
        setProductsNumber(filteredProducts.length);
    }, [selectedCategories])

    return (
        <>
           <Header/>
            <PageHeader title={category}/>
            <section className="products-page pb-5">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-3 category-list-container">
                            <CategoryList/>
                        </div> */}

                        <div className="col-md-9 products-container">
                            <div className="products-filter-container mb-4 d-flex justify-content-between align-items-center">
                                {/* <p className="grey-color">Вкупно <span className="primary-color fw-bold">{originalProductsData.length}</span> производи</p> */}
                                <div className="products-filter-wrapper d-flex gap-4">
                                    {/* <div className="products-filter-show d-flex align-items-center">
                                        <p>Прикажи: </p>
                                        <select ref={showRef} onChange={() => handleShowNumberProducts(showRef, setShowNumberProducts, originalProductsData, setCategoryProductsData)}>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="12">12</option>
                                        </select>
                                    </div> */}
                                    {/* <div className="products-filter-sortby d-flex align-items-center">
                                        <p>Сортирај: </p>
                                        <select onChange={(e) => handleSortBy(e, categoryProductsData, setCategoryProductsData, originalProductsData)}>
                                            <option value="base">Основно</option>
                                            <option value="lowPrice">Најниска цена</option>
                                            <option value="highPrice">Највисока цена</option>
                                        </select>
                                    </div> */}
                                </div>
                            </div>
                           <div className="row">
                           <div className="row">
            
            <SwiperComponent list={dataCompanies.filter((item) => item.category === category)}/>
          </div>
                            
                            {/* {
                                    categoryProductsData&&categoryProductsData.map((item, index) => (
                                        <ProductCard props={item} key={index}/>
                                    ))
                                } */}
                                 {/* <PaginationControl
                                    page={page}
                                    between={4}
                                    total={productsNumber}
                                    limit={showNumberProducts}
                                    changePage={(page) => {
                                    setPage(page)
                                    }}
                                    ellipsis={1}
                                /> */}
                           </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
            <select ref={showRef} onChange={() => handleShowNumberProducts(showRef, setShowNumberProducts, originalProductsData, setCategoryProductsData)} style={{display: 'none'}}></select>
        </>
    )
}

export default ProductsPage;