import Breadcrumb from '../Breadcrumb/Breadcrumb';
import './pageheaderstyle.css';

const PageHeader = ({ title }) => {

    const headerTitles = {
      pekarstvo: 'Пекарство',
      slatkarstvo: 'Слаткарство',
      brasna: 'Брашна',
      recepti: 'Рецепти',
    };
  
    const headerTitle = headerTitles[title] || title;
  
    return (
      <section className="pt-5 pb-5">
        <div className="container page-header-container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-capitalize">{headerTitle}</h1>
              <Breadcrumb category={headerTitle}/>
            </div>
          </div>
        </div>
      </section>
    );
  };

export default PageHeader;