import { BiHomeAlt } from 'react-icons/bi';
import './breadcrumbstyle.css';
import { AiOutlineRight } from 'react-icons/ai';

const Breadcrumb = ({category}) => {
    return (
        <>
            <div className='breadcrumb'>
                <p className='breadcrumb-inner'><BiHomeAlt/> Дома <AiOutlineRight/> Категории <AiOutlineRight/> <span className='primary-color fw-bold'>{category}</span></p>
            </div>
        </>
    )
}

export default Breadcrumb;