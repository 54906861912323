import { useContext, useRef, useState } from "react";
import { CartContext } from "../../Utils/Hooks/CartContextComponent";
import Button from "../Button/Button";
import { handleSendEmailToOwner } from "../../Utils/App Functions/app-functions";
import axios from "axios";


const NaracajGolemoForm = () => {

    const { cartItems, setCartItems } = useContext(CartContext);

    const nameRef = useRef();
    const surnameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const addressRef = useRef();
    const companyRef = useRef();
    const danocenBroj = useRef();

    const [error, setError] = useState();

    return (
        <>
        <form>
            <div className="mb-3">
                <label htmlFor="inputName" className="form-label">Внесете име</label>
                <input type="text" className="form-control" id="inputName" ref={nameRef}/>
            </div>
            <div className="mb-3">
                <label htmlFor="inputSurname" className="form-label">Внесете презиме</label>
                <input type="text" className="form-control" id="inputSurname" ref={surnameRef}/>
            </div>
            <div className="mb-3">
                <label htmlFor="inputEmail" className="form-label">Внесете Е-Маил адреса</label>
                <input type="email" className="form-control" id="inputEmail" ref={emailRef}/>
            </div>
            <div className="mb-3">
                <label htmlFor="inputPhone" className="form-label">Внесете телефонски број</label>
                <input type="number" className="form-control" id="inputPhone" ref={phoneRef}/>
            </div>
            <div className="mb-3">
                <label htmlFor="inputAddress" className="form-label">Внесете адреса</label>
                <input type="text" className="form-control" id="inputAddress" ref={addressRef}/>
            </div>
            <div className="mb-3">
                <label htmlFor="inputCompany" className="form-label">Внесете име на компанија</label>
                <input type="text" className="form-control" id="inputCompany" ref={companyRef}/>
            </div>
            <div className="mb-3">
                <label htmlFor="inputDanocenBroj" className="form-label">Внесете даночен број</label>
                <input type="text" className="form-control" id="inputDanocenBroj" ref={danocenBroj}/>
            </div>
            {error ? <p className='text-danger mt-3'>{error}</p> : ``}
            <Button text="Поставете нарачка" classes="mt-3" onClick={() => handleSendEmailToOwner(
                nameRef.current.value,
                surnameRef.current.value,
                emailRef.current.value,
                phoneRef.current.value,
                addressRef.current.value,
                companyRef.current.value,
                danocenBroj.current.value,
                setError,
                setCartItems
            )}/>
        </form>
        </>
    )
}

export default NaracajGolemoForm;