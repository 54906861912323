import { createContext, useState } from "react";

export const CategoriesContext = createContext();

const CategoriesContextComponent = ({children}) => {

    const [selectedCategories, setSelectedCategories] = useState([]);


    return  <CategoriesContext.Provider value={{ selectedCategories, setSelectedCategories }}>
    {children}
    </CategoriesContext.Provider>
}

export default CategoriesContextComponent;