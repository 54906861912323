import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import ProductsPage from './Pages/Products Page/ProductsPage';
import ProductPage from './Pages/Product Page/ProductPage';
import AboutPage from './Pages/About Page/AboutPage';
import NaracajGolemoPage from './Pages/Naracaj Golemo Page/NaracajGolemoPage';
import CartContextComponent from './Utils/Hooks/CartContextComponent';
import ContactPage from './Pages/Contact Page/ContactPage';
import CategoriesContextComponent from './Utils/Hooks/CategoriesContextComponent';
import CompanyProductsPage from './Pages/Company Products Page/CompanyProductsPage';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>
  },
  {
    path: '/category/:category',
    element: <ProductsPage/>
  },
  {
    path: '/company/:company',
    element: <CompanyProductsPage/>
  },
  {
    path: '/product/:title',
    element: <ProductPage/>
  },
  {
    path: '/about',
    element: <AboutPage/>
  },
  {
    path: '/naracaj-golemo',
    element: <NaracajGolemoPage/>
  },
  {
    path: '/contact',
    element: <ContactPage/>
  }
]);


root.render(
  <React.StrictMode>
     <CategoriesContextComponent>
      <CartContextComponent>
        <RouterProvider router={router} />
        </CartContextComponent>
     </CategoriesContextComponent>
  </React.StrictMode>
);