import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './swiperstyle.css';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';

const SwiperComponent = ({list}) => {

    return (
        <>
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      spaceBetween={10}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      noSwiping={true}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
     {
        list.map((item) => (
            <SwiperSlide>
                <Link to={`${item.url}`}>
                <div className='swiper-box text-center d-flex flex-column align-items-center justify-content-center'>
                    <img src={item.image} alt=''/>
                    <p className='mt-2 fw-bold'>{item.title}</p>
                </div>
                </Link>
            </SwiperSlide>
        ))
     }
    </Swiper>
        </>
    )
}

export default SwiperComponent;